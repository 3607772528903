<template>
  <section class="history-wrappepr">
    <card title="库存修改历史">
      <el-button class="card-btn" size="small" type="primary" @click="goBack"
        >返回</el-button
      ><el-table :data="historyList" border @filter-change="filterTagTable">
        <el-table-column
          label="商品名"
          align="center"
          prop="pName"
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          :formatter="formatOperation"
        ></el-table-column>
        <el-table-column
          label="操作账号"
          align="center"
          prop="uname"
        ></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next,jumper"
        :total="totalCount"
        style="margin: 20px 0 0 30px;"
      >
      </el-pagination
    ></card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      orderstate: [
        { text: "+", value: 0 },
        { text: "-", value: 1 }
      ],
      historyList: [],
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0
    };
  },
  computed: {},
  methods: {
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getHistory();
    },
    goBack() {
      this.$router.back(-1);
    },
    filterTagTable(filters) {
      if (filters.orderstate) {
        this.orderstateFilter =
          typeof filters.orderstate[0] == "number" ? filters.orderstate[0] : -1;
      } else if (filters.roomtype) {
        this.roomtypeFilter =
          typeof filters.roomtype[0] == "number" ? filters.roomtype[0] : -1;
      }
    },
    getHistory() {
      this.$axios({
        url: "/back/stock/getAllStockHistory",
        params: {
          page: this.currentPage
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.PageSize = res.data.data.pageSize;
          this.totalCount = res.data.data.total;
          this.historyList = res.data.data.list;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
        setTimeout(() => {
          this.$openLoading().close();
        }, 200);
      });
    },
    formatOperation(row, column) {
      return row.operation + row.account;
    }
  },
  created() {
    this.$openLoading();
    this.getHistory();
  }
};
</script>

<style></style>
